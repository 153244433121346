import * as React from "react"
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import './scss/_global.scss'
import './scss/home.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
// markup
const IndexPage = () => {
  // [month, id, name] - first entry is first image to appear
  const screenshots = [["March", "403", "Carlo Derbedrossian"], ["February", "050", "Jeremy Sam"], ["January", "162", "Rick Neill"], ["December", "188", "Stuart Blackburn"], ["November", "050", "Jeremy Sam"],["October", "188", "Stuart Blackburn"], ["September", "312", "Jeremy Sam"], ["August", "188", "Stuart Blackbourn"], ["July", "188", "Stuart Blackbourn"],["June", "217", "Jamyson Dore"], ["May", "188", "Stuart Blackbourn"], ["April", "246", "Sandeep Nathwani"],]

  const [index, updateIndex] = React.useState(0)
  const [stats, updateStats] = React.useState([])

  function nextImage() {
    let current = index
    if (index + 1 > screenshots.length - 1) {
      updateIndex(0)
    } else {
      updateIndex(current + 1)
    }
  }

  function lastImage() {
    let current = index
    if (index - 1 < 0) {
      updateIndex(screenshots.length - 1)
    } else {
      updateIndex(current - 1)
    }
  }

  React.useEffect(() => {
    fetch("https://api.vwestjet.org/stats")
      .then(response => response.json())
      .then(resultData => {
        updateStats([resultData.pilots, resultData.flights, resultData.pireps, resultData.hours])
      })
  }, [])

  return (
    <main>
      <title>virtual WestJet | Homepage</title>
      <Navbar />
      <div className="slide1">
        <div id="slideshow">
          <img src={'/arrow.svg'} className="arrow" id="left-arrow" onClick={() => lastImage()} alt="Arrow"/>
          <div className="home-image">
            <img src={`/images/contest/${screenshots[index][0]}.png`} className="home-image-1" alt="Screenshot of the Month" />
            <span className="image-credit">{screenshots[index][0]} Screenshot Contest Winner | <span id="winner-name"> vWJ{screenshots[index][1]} | {screenshots[index][2]} </span></span>
          </div>
          <img src={'/arrow.svg'} className="arrow" onClick={() => nextImage() } alt="Arrow"/>
        </div>
        <div className="moto">
          <h2>The Canadian Virtual Airline That Welcomes All</h2>
        </div>
      </div>
      <div className="slide2">
        <div className="double-container">
          <div className="stats-container">
            <h1>Stats</h1>
            <div className="stats">
              <div className="stat"><span className="stat-title">Registered Pilots:</span><span className="stat-number">{stats[0]}</span></div>
              <div className="stat"><span className="stat-title">Active Flights:</span><span className="stat-number">{stats[1]}</span></div>
              <div className="stat"><span className="stat-title">PIREPS Filed:</span><span className="stat-number">{stats[2]}</span></div>
              <div className="stat"><span className="stat-title">Flight Hours:</span><span className="stat-number">{stats[3]}</span></div>
            </div>
          </div>
          <div className="partners-container">
            <h1>Partners</h1>
            <a href="https://vatsim.net" target="_blank" rel="noreferrer" className="partner"><img src={'/images/partnerships/vatsim.png'} alt="vatsim" className="partner-image"/></a>
            <hr />
            <a href="https://www.fsimstudios.com/" target="_blank" rel="noreferrer" className="partner"><img src={'/images/partnerships/fsim.png'} alt="fsim studios" className="partner-image"/></a>
            <hr />
            <a href=" https://www.tfdidesign.com/" target="_blank" rel="noreferrer" className="partner"><img src={'/images/partnerships/tfdi.png'} alt="tfdi design" className="partner-image"/></a>
            <hr />
            <a href=" https://orbxdirect.com/partner/hues-by-horizon" target="_blank" rel="noreferrer" className="partner"><img src={'/images/partnerships/hues.png'} alt="tfdi design" className="partner-image"/></a>
          </div>
          <div className="discord-container">
            <h1>Discord</h1>
            <iframe src="https://discord.com/widget?id=736619729577574490&theme=dark" className="discord" allowtransparency="true" border="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts" title="discord"></iframe>
          </div>
        </div>
      </div>
      <div className="slide3">
        <h1>Perks</h1>
        <div className="slide3-container">
          <div className="slide3-content">
            <ul>
              <li>Professional and Secure Website</li>
              <li>Up to Date flight Schedules</li>
              <li>smartCARS Acars Client</li>
              <li>Highly Qualified Staff</li>
              <li>Active Social Network</li>
            </ul>
          </div>
          <div className="slide3-image">
            <img src={'/images/home_image_2.png'} className="home-image-2" alt="Window view from Westjet 737" />
            <span className="image-credit">Screenshot courtesy of Luke Wightman</span>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default IndexPage
